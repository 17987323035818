// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/truthist-secondary-button.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/truthist-secondary-button.tsx");
  import.meta.hot.lastModified = "1733459677540.6008";
}
// REMIX HMR END

import { Button, useColorModeValue } from "@chakra-ui/react";
import { TruthistB3, TruthistB4, TruthistB5 } from "./truthist-styles";
export default function TruthistSecondaryButton({
  children,
  textSize,
  ...rest
}) {
  _s();
  const hoverColor = useColorModeValue("gray.400", "gray.600");
  return <Button size={"sm"} border={0} borderRadius={"md"} _hover={{
    bgColor: hoverColor
  }} variant={"outline"} {...rest}>
      {textSize && textSize === "xs" ? <TruthistB5>{children}</TruthistB5> : textSize === "lg" ? <TruthistB3>{children}</TruthistB3> : <TruthistB4>{children}</TruthistB4>}
    </Button>;
}
_s(TruthistSecondaryButton, "TFlVPCESgEmsdYu+lHwd0opj1Vo=", false, function () {
  return [useColorModeValue];
});
_c = TruthistSecondaryButton;
var _c;
$RefreshReg$(_c, "TruthistSecondaryButton");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;